"use client";

import {
	Box,
	BoxProps,
} from "@mui/material";
import useFashionPreference from "hooks/useFashionPreference";
import { LogoJsonLd } from "next-seo";
import Link, {
	LinkProps,
} from "next/link";
import { FC } from "react";
import { cdpClient } from "utils/analytics";
import {
	FRONTEND_URL,
	SITE_NAME,
} from "utils/config";
import LazyImage, {
	LazyImageProps,
} from "./LazyImage";

const ConditionalLink = ({
	hasLink,
	children,
	...props
}: {
	hasLink: boolean;
	children: React.ReactNode;
} & LinkProps) => {
	return hasLink ? (
		<Link {...props}>{children}</Link>
	) : (
		<>{children}</>
	);
};

export type LogoProps = {
	src?: string;
	alt?: string;
	height?: number;
	width?: number;
	variant?: "dark" | "light";
	size?: "small" | "normal";
	muiBoxProps?: BoxProps;
	nextLinkProps?: LinkProps;
	lazyImageProps?: LazyImageProps;
	principal?: boolean;
	hasLink?: boolean;
};

const Logo: FC<LogoProps> = ({
	src: givenSrc,
	alt,
	height,
	width,
	variant = "dark",
	size = "samll",
	muiBoxProps,
	nextLinkProps,
	principal = false,
	hasLink = true,
	...lazyImageProps
}) => {
	const { preferredHomePageSlug: url } =
		useFashionPreference();

	const src =
		givenSrc ||
		(variant === "dark"
			? size === "normal"
				? "/assets/images/noomidoo-logos/noomidoo-logo-rect-black.svg"
				: "/assets/images/noomidoo-logos/noomidoo-logo-squ-black.svg"
			: size === "normal"
			? "/assets/images/noomidoo-logos/noomidoo-logo-rect-white.svg"
			: "/assets/images/noomidoo-logos/noomidoo-logo-squ-white.svg");

	const handleClick = () => {
		cdpClient?.track(
			principal
				? "Header Logo Clicked"
				: "Footer Logo Clicked",
			{
				eventType: "click",
				category: "Logo",
				label: "Logo",
			}
		);
	};

	return (
		<Box {...muiBoxProps}>
			{principal && (
				<LogoJsonLd
					useAppDir={true}
					logo={`${FRONTEND_URL}${src}`}
					url={FRONTEND_URL}
				/>
			)}
			<ConditionalLink
				{...nextLinkProps}
				href={url}
				hasLink={hasLink}>
				<LazyImage
					{...lazyImageProps}
					onClick={handleClick}
					loading={
						principal ? "eager" : "lazy"
					}
					priority={principal}
					applyBlurFilter={!principal}
					height={
						height
							? height
							: size === "normal"
							? 44
							: 50
					}
					width={
						width
							? width
							: size === "normal"
							? 132
							: 50
					}
					src={src}
					alt={
						alt
							? alt
							: `Logo de ${SITE_NAME}`
					}
				/>
			</ConditionalLink>
		</Box>
	);
};

export default Logo;
