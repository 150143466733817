// create context provider
import useToggleState from "hooks/useToggleState";
import {
	createContext,
	Dispatch,
	SetStateAction,
	useContext,
	useState,
} from "react";

export const AuthDialogContext =
	createContext<Value | null>(null);

type Value = {
	state: boolean;
	close: () => void;
	open: () => void;
	toggle: () => void;
	setTitle: Dispatch<
		SetStateAction<string>
	>;
	title: string;
	setIsFullScreen: Dispatch<
		SetStateAction<boolean>
	>;
	isFullScreen: boolean;
	setHasHeader: Dispatch<
		SetStateAction<boolean>
	>;
	hasHeader: boolean;
	setIsCenter: Dispatch<
		SetStateAction<boolean>
	>;
	isCenter: boolean;
};

export const AuthDialogProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const { state, close, open, toggle } =
		useToggleState();

	const [title, setTitle] = useState(
		"Connexion"
	);

	const [
		isFullScreen,
		setIsFullScreen,
	] = useState(true);
	const [hasHeader, setHasHeader] =
		useState(false);
	const [isCenter, setIsCenter] =
		useState(true);

	const toggleAuthDialog = () => {
		toggle();
	};

	const value: Value = {
		state,
		close,
		open,
		toggle: toggleAuthDialog,
		setTitle,
		title,
		setIsFullScreen,
		isFullScreen,
		setHasHeader,
		hasHeader,
		setIsCenter,
		isCenter,
	};
	return (
		<AuthDialogContext.Provider
			value={value}>
			{children}
		</AuthDialogContext.Provider>
	);
};

export const useAuthDialogContext =
	() => {
		const context = useContext(
			AuthDialogContext
		);
		if (context === null) {
			throw new Error(
				"useAuthDialogContext must be used within a AuthDialogProvider"
			);
		}
		return context;
	};
