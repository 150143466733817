"use client";

import {
	Box,
	styled,
} from "@mui/material";
import { layoutConstant } from "utils/constants";

// styled component
export const AuthHeaderWrapper = styled(
	Box
)(({ theme }) => ({
	zIndex: 3,
	position: "relative",
	height: layoutConstant.headerHeight,
	transition:
		"height 250ms ease-in-out",
	background:
		theme.palette.background.paper,
	[theme.breakpoints.down("sm")]: {
		height:
			layoutConstant.mobileHeaderHeight,
	},
}));
