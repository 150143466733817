import { Alert } from "@mui/material";
import BazaarCTA from "components/BazaarCta";
import BazaarPasswordField from "components/BazaarPasswordField";
import { useAccount } from "contexts/AccountContext";
import { useAuthDialogContext } from "contexts/AuthDialogContext";
import { useFormik } from "formik";
import { FC, useState } from "react";
import { cdpClient } from "utils/analytics";

interface Props {}

const PasswordLoginForm: FC<
	Props
> = ({}) => {
	const { handleLogIn, checkedEmail } =
		useAccount();
	const { close: closeAuthDialog } =
		useAuthDialogContext();

	const [isLoading, setIsLoading] =
		useState<boolean>(false);
	const [error, setError] = useState<
		null | string
	>(null);

	const handleFormSubmit =
		async (values: {
			password: string;
		}) => {
			cdpClient?.track(
				"Password Login Form Submitted",
				{
					eventType: "click",
					category: "Auth",
					label: "Password Login Form",
					email: checkedEmail,
					...values,
				}
			);
			const { password } = values;
			setIsLoading(true);
			try {
				const wasSuccessful =
					await handleLogIn({
						email: checkedEmail ?? "",
						password,
						setError,
					});

				if (!wasSuccessful) return;
				closeAuthDialog();
			} finally {
				setIsLoading(false);
			}
		};

	const {
		values,
		errors,
		touched,
		handleBlur,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues,
		onSubmit: handleFormSubmit,
	});

	return (
		<form onSubmit={handleSubmit}>
			{/* password field */}

			<BazaarPasswordField
				required
				name='password'
				label='Mot de passe'
				onBlur={handleBlur}
				onChange={handleChange}
				value={values.password}
				error={
					(!!touched.password &&
						!!errors.password) ||
					!!error
				}
				helperText={
					(touched.password &&
						errors.password) as string
				}
			/>

			{error && (
				<Alert
					severity='error'
					sx={{ mb: 2 }}>
					{error}
				</Alert>
			)}
			<BazaarCTA
				sx={{
					mb: 2,
					mt: 3,
					height: 44,
				}}
				fullWidth
				loading={isLoading}
				type='submit'
				variant='contained'>
				Se connecter
			</BazaarCTA>
		</form>
	);
};

// initialValues
const initialValues = {
	password: "",
};

export default PasswordLoginForm;
