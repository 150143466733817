import LoadingLayout from "components/layouts/LoadingLayout";
import PrerequisitiesAlert from "pages-sections/prerequisities/PrerequisitiesAlert";
import {
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";

export const PrerequisitiesContext =
	createContext<Value | null>(null);

type Value = {
	isLoacalStorageEnabled: Boolean | null;
	isCookiesEnabled: Boolean | null;
};

export const PrerequisitiesProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [
		isLoacalStorageEnabled,
		setIsLoacalStorageEnabled,
	] = useState<null | Boolean>(null);

	useEffect(() => {
		async function checkStorage() {
			// Check for Local Storage
			try {
				localStorage.setItem(
					"test",
					"test"
				);
				localStorage.removeItem("test");
				return true;
			} catch (e) {
				return false; // Local storage is not enabled
			}
		}

		if (
			isLoacalStorageEnabled === null
		) {
			checkStorage().then((result) => {
				setIsLoacalStorageEnabled(
					result
				);
			});
		}
	}, [
		isLoacalStorageEnabled,
		setIsLoacalStorageEnabled,
	]);

	const [
		isCookiesEnabled,
		setIsCookiesEnabled,
	] = useState<null | Boolean>(null);

	useEffect(() => {
		async function checkCookies() {
			// Check for Cookies
			try {
				document.cookie =
					"cookietest=1";
				const cookiesEnabled =
					document.cookie.indexOf(
						"cookietest="
					) !== -1;

				// Clean up test cookie
				document.cookie =
					"cookietest=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

				return cookiesEnabled;
			} catch (e) {
				return false; // Cookies are not enabled
			}
		}

		if (isCookiesEnabled === null) {
			checkCookies().then((result) => {
				setIsCookiesEnabled(result);
			});
		}
	}, [
		isCookiesEnabled,
		setIsCookiesEnabled,
	]);

	if (
		isLoacalStorageEnabled === null ||
		isCookiesEnabled === null
	) {
		return <LoadingLayout />;
	}

	if (
		isLoacalStorageEnabled === false
	) {
		return (
			<PrerequisitiesAlert
				title='Veuillez activer le stockage local pour continuer'
				description="Notre site Web utilise le stockage local pour permettre l'ajout de produits à votre panier et d'autres fonctionnalités indispensables au bon fonctionnement du site."
				reloadButtonText="Ok, j'ai activé le stockage local"
			/>
		);
	}

	const value: Value = {
		isLoacalStorageEnabled,
		isCookiesEnabled,
	};
	return (
		<PrerequisitiesContext.Provider
			value={value}>
			{children}
		</PrerequisitiesContext.Provider>
	);
};

export const usePrerequisitiesContext =
	() => {
		const context = useContext(
			PrerequisitiesContext
		);
		if (context === null) {
			throw new Error(
				"usePrerequisitiesContext must be used within a PrerequisitiesProvider"
			);
		}
		return context;
	};
