"use client";

import clsx from "clsx";
import Logo from "components/Logo";
import {
	FlexBetween,
	FlexBox,
} from "components/flex-box";
import useDeviceSize from "hooks/useDeviceSize";
import { FC } from "react";
import { AuthStyledContainer } from "./AuthHeaderContainer";
import { AuthHeaderWrapper } from "./AuthHeaderWrapper";

// ==============================================================
type AuthHeaderProps = {
	isFixed?: boolean;
	className?: string;
};
// ==============================================================

const AuthHeader: FC<
	AuthHeaderProps
> = ({
	//isFixed,
	className,
}) => {
	const { upMd } = useDeviceSize();
	// FOR SMALLER DEVICE
	if (!upMd) {
		return (
			<AuthHeaderWrapper
				className={clsx(className)}>
				<AuthStyledContainer>
					<FlexBetween
					// width="100%"
					>
						{/* MIDDLE CONTENT - LOGO */}
						<Logo principal />
					</FlexBetween>
				</AuthStyledContainer>
			</AuthHeaderWrapper>
		);
	}

	return (
		<AuthHeaderWrapper
			className={clsx(className)}>
			<AuthStyledContainer>
				{/* LEFT CONTENT - LOGO AND CATEGORY */}
				<FlexBox
					mr={2}
					minWidth='170px'
					alignItems='center'>
					<Logo principal />
				</FlexBox>
			</AuthStyledContainer>
		</AuthHeaderWrapper>
	);
};

export default AuthHeader;
