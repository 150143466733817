"use client";
import { useMutation } from "@tanstack/react-query";
import { useMeCustomer } from "hooks/useMeCustomer";
import { Customer } from "models/Customer.model";
import { useRouter } from "next/navigation";
import { useSnackbar } from "notistack";
import React, {
	Dispatch,
	SetStateAction,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import authStoreApi from "__api__/storeAPI/auth";

import useSettings from "hooks/useSettings";
import customerStoreAPI from "__api__/storeAPI/customer";
import verifyEmailAPI from "__api__/verifyEmailAPI/email";
import { cdpClient } from "utils/analytics";
import {
	ACCOUNT_ROOT,
	AUTH_ROOT,
} from "utils/config";
import { handleDevError } from "utils/handle-dev-error";
import handleError from "utils/handleError";
import { useAuthDialogContext } from "./AuthDialogContext";
import AuthDialog from "components/dialog-and-drawer/AuthDialog";

export type UpdateCustomerDetailsDTO = {
	first_name: string;
	last_name: string;
	phone: string;
	day: string;
	month: string;
	year: string;
	setError?: React.Dispatch<
		React.SetStateAction<string>
	>;
};

export interface CheckIfEmailExistsProps {
	email: string;
	setError: Dispatch<
		SetStateAction<string>
	>;
}

const formatBirthDate = (
	day: string,
	month: string,
	year: string
) => {
	return `${day}/${month}/${year}`;
};

interface IAccountContext {
	customer?: Omit<
		Customer,
		"password_hash"
	>;
	loadingCustomer: boolean;
	isCustomerRefetching: boolean;
	retrievingCustomer: boolean;
	checkIsRestricted: () => void;
	setAfterAuthDestination: (
		destination: string
	) => void;
	checkIsAllreadyLoggedIn: () => void;
	refetchCustomer: () => void;
	sendEmailCode: (params: {
		email: string;
		setError: React.Dispatch<
			React.SetStateAction<string>
		>;
	}) => Promise<Boolean>;

	verifyEmailWithCode: (params: {
		code: string;
		newEmail: string;
		setError: React.Dispatch<
			React.SetStateAction<string>
		>;
	}) => Promise<Boolean>;

	handleRegister: (params: {
		first_name: string;
		last_name: string;
		verification_code: string;
		email: string;
		password: string;
		setError?: React.Dispatch<
			React.SetStateAction<string>
		>;
	}) => Promise<Boolean>;

	handleLogIn: (params: {
		email: string;
		password: string;
		setError?: React.Dispatch<
			React.SetStateAction<string>
		>;
		doReloadPage?: boolean;
	}) => Promise<Boolean>;

	updateCustomerEmail: (params: {
		newEmail: string;
		code: string;
		setError: React.Dispatch<
			React.SetStateAction<string>
		>;
	}) => Promise<Boolean>;

	updateCustomerPassword: (params: {
		oldPassword: string;
		newPassword: string;
		setError: React.Dispatch<
			React.SetStateAction<string>
		>;
	}) => Promise<Boolean>;

	updateCustomerDetails: (
		params: UpdateCustomerDetailsDTO
	) => Promise<Boolean>;

	handleLogout: () => void;

	checkedEmail: string | null;
	setCheckedEmail: Dispatch<
		SetStateAction<string | null>
	>;
	emailExists: boolean | null;
	setEmailExists: Dispatch<SetStateAction<boolean> | null>;
	checkIfEmailExists: ({
		email,
		setError,
	}: CheckIfEmailExistsProps) => Promise<Boolean>;

	hasSentEmailCodeToCheckedEmail: boolean;
	setHasSentEmailCodeToCheckedEmail: Dispatch<
		SetStateAction<boolean>
	>;

	isRequestResetPassword: boolean;
	setIsRequestResetPassword: Dispatch<
		SetStateAction<boolean>
	>;

	error: string;
	resetError: () => void;
}

const AccountContext =
	createContext<IAccountContext | null>(
		null
	);

interface AccountProviderProps {
	children?: React.ReactNode;
}

const handleDeleteSession = () => {
	return authStoreApi.logCustomerOut();
};

export const AccountProvider = ({
	children,
}: AccountProviderProps) => {
	const router = useRouter();
	const { open: openAuthDialog } =
		useAuthDialogContext();

	const [isLoggedIn, setIsLoggedIn] =
		useState(false);

	const {
		customer,
		isLoading: loadingCustomer,
		refetch,
		isRefetching: isCustomerRefetching,
		remove,
	} = useMeCustomer({
		staleTime: 1000 * 60 * 5,
		refetchOnMount: isLoggedIn,
		refetchOnWindowFocus: isLoggedIn,
		refetchOnReconnect: isLoggedIn,
		// enabled: true,
		onError: (error) => {
			if (!isLoggedIn) return;
			remove();
			handleError({
				error,
				setError,
				errorMessage:
					"Votre session a expiré. Veuillez vous reconnecter si vous le souhaitez.",
				principalAction: () => {
					openAuthDialog();
				},
				principalActionLabel:
					"Me reconnecter",
				severity: "info",
			});

			resetToDefault();
			cdpClient?.reset();
		},
		onSuccess: () => {
			resetError();
		},
	});

	useEffect(() => {
		setIsLoggedIn(!!customer);
	}, [customer]);

	const retrievingCustomer =
		loadingCustomer ||
		isCustomerRefetching;

	const randomReferenceForTab = useRef(
		Math.random()
	);

	const { settings, updateSettings } =
		useSettings();

	useEffect(() => {
		const channel =
			new BroadcastChannel(
				"user_session"
			);

		channel.onmessage = (message) => {
			if (
				(message.data.type ===
					"logged_out" ||
					message.data.type ===
						"logged_in" ||
					message.data.type ===
						"registered") &&
				message.data.reference !==
					randomReferenceForTab.current
			) {
				// Handle logout in this window

				window.location.reload();
			}
		};
		return () => channel.close();
	}, [router, refetch]);

	const [error, setError] =
		useState("");

	const resetError = () => {
		setError("");
	};

	useEffect(() => {
		const channel =
			new BroadcastChannel(
				"customer_info"
			);
		channel.onmessage = (message) => {
			if (
				message.data.type ===
					"customer_refetch" &&
				message.data.reference !==
					randomReferenceForTab.current
			) {
				refetch();
			}
		};
		return () => channel.close();
	}, [refetch]);

	useEffect(() => {
		const channel =
			new BroadcastChannel(
				"customer_info"
			);

		channel.postMessage({
			type: "customer_refetch",
			reference:
				randomReferenceForTab.current,
		});
		return () => channel.close();
	}, [customer]);

	const { enqueueSnackbar } =
		useSnackbar();

	const [
		afterAuthDestination,
		setAfterAuthDestination,
	] = useState(`${ACCOUNT_ROOT}`);

	const [
		hasSentEmailCodeToCheckedEmail,
		setHasSentEmailCodeToCheckedEmail,
	] = useState(false);

	const checkIsRestricted =
		useCallback(() => {
			if (
				!retrievingCustomer &&
				!customer
			) {
				enqueueSnackbar(
					"Vous devez vous connecter pour accéder à cette page.",
					{
						variant: "warning",
					}
				);
				router.replace(`${AUTH_ROOT}`);
			}
		}, [
			retrievingCustomer,
			customer,
			enqueueSnackbar,
			router,
		]);

	const checkIsAllreadyLoggedIn =
		useCallback(() => {
			if (
				!retrievingCustomer &&
				customer
			) {
				enqueueSnackbar(
					"Bienvenue" +
						" " +
						customer?.first_name +
						".",
					{
						variant: "success",
					}
				);
				router.replace(
					afterAuthDestination
				);
			}
		}, [
			enqueueSnackbar,
			retrievingCustomer,
			customer,
			router,
			afterAuthDestination,
		]);

	const useDeleteSession = useMutation({
		mutationFn: handleDeleteSession,
		mutationKey: ["delete-session"],
	});

	const [
		checkedEmail,
		setCheckedEmail,
	] = useState<string | null>(null);

	const [emailExists, setEmailExists] =
		useState<boolean | null>(null);

	const [
		isRequestResetPassword,
		setIsRequestResetPassword,
	] = useState(false);

	const resetToDefault = () => {
		setCheckedEmail(null);
		setEmailExists(null);
		setIsRequestResetPassword(false);
		setHasSentEmailCodeToCheckedEmail(
			false
		);
	};

	const checkIfEmailExists = async ({
		email,
		setError,
	}: CheckIfEmailExistsProps) => {
		try {
			const res =
				await authStoreApi.checkifEmailExists(
					email
				);

			setCheckedEmail(email);

			if (res.exists === true) {
				setEmailExists(true);
			} else if (res.exists === false) {
				setEmailExists(false);
			} else {
				setEmailExists(null);
			}

			return res.exists;
		} catch (err) {
			handleError({
				error: err,
				setError,
				errorMessage:
					"Nous n'avons pas pu vérifier votre adresse e-mail. Veuillez réessayer s'il vous plait.",
				notifyError: false,
			});
			return false;
		}
	};

	const updateCustomerEmail =
		async (params: {
			newEmail: string;
			code: string;
			setError: React.Dispatch<
				React.SetStateAction<string>
			>;
		}) => {
			const {
				newEmail,
				code,
				setError,
			} = params;
			try {
				cdpClient?.track(
					"Email Update Form Submitted",
					{
						eventType: "click",
						category: "Account",
						label: "Email Update Form",
						newEmail,
					}
				);
				await customerStoreAPI.updateCustomer(
					{
						email: newEmail,
						code: code.toString(),
					}
				);
				refetch();
				resetToDefault();
				enqueueSnackbar(
					"Votre adresse e-mail a été mise à jour avec succès.",
					{
						variant: "success",
					}
				);
				return true;
			} catch (error) {
				handleError({
					error,
					setError,
					errorMessage:
						"Veuillez vous assurer que le code de vérification que vous avez saisi est correct et réessayez s'il vous plaît.",
					notifyError: false,
				});
				return false;
			}
		};

	const updateCustomerPassword =
		async (params: {
			oldPassword: string;
			newPassword: string;
			setError: React.Dispatch<
				React.SetStateAction<string>
			>;
		}) => {
			const {
				oldPassword,
				newPassword,
				setError,
			} = params;

			if (newPassword === oldPassword) {
				handleError({
					error: new Error(
						"Votre nouveau mot de passe doit être différent de votre mot de passe actuel."
					),
					errorMessage:
						"Votre nouveau mot de passe doit être différent de votre mot de passe actuel.",
					notifyError: false,
					setError,
				});

				return false;
			}

			try {
				cdpClient?.track(
					"Password Update Form Submitted",
					{
						eventType: "click",
						category: "Account",
						label:
							"Password Update Form",
					}
				);
				await authStoreApi.logCustomerIn(
					{
						email:
							customer?.email ?? "",
						password: oldPassword,
					}
				);
			} catch (error) {
				handleError({
					error,
					errorMessage:
						"Votre mot de passe actuel est incorrect",
					notifyError: false,
					setError,
				});
				return false;
			}

			try {
				await customerStoreAPI.updateCustomer(
					{
						password: newPassword,
					}
				);
				refetch();
				resetToDefault();
				enqueueSnackbar(
					"Votre mot de passe a été mis à jour avec succès.",
					{
						variant: "success",
					}
				);
				return true;
			} catch (error) {
				handleError({
					error,
					setError,
					errorMessage:
						"Quelque chose s'est mal passé, veuillez réessayer s'il vous plaît.",
				});
				return false;
			}
		};

	const updateCustomerDetails = async (
		params: UpdateCustomerDetailsDTO
	) => {
		const {
			first_name,
			last_name,
			phone,
			day,
			month,
			year,
			setError,
		} = params;

		// if every field value is equal to its equivalent in customer return an error

		const birthDate = (
			customer?.metadata
				?.birth_date as string
		)
			?.toString()
			.split("/");

		if (
			first_name ===
				customer?.first_name &&
			last_name ===
				customer?.last_name &&
			phone === customer?.phone &&
			day === birthDate?.[0] &&
			month === birthDate?.[1] &&
			year === birthDate?.[2]
		) {
			enqueueSnackbar(
				"Vous n'avez fait aucun changement",
				{
					variant: "info",
				}
			);
			return true;
		}

		cdpClient?.track(
			"Personal Details Form Submitted",
			{
				eventType: "click",
				category: "Account",
				label:
					"Personal Details Profile Form",
				first_name,
				last_name,
				phone,
				birth_date: formatBirthDate(
					day,
					month,
					year
				),
			}
		);

		try {
			await customerStoreAPI.updateCustomer(
				{
					last_name,
					first_name,
					metadata: {
						birth_date: formatBirthDate(
							day,
							month,
							year
						),
					},
					phone,
				}
			);

			refetch();
			resetToDefault();
			enqueueSnackbar(
				"Vos informations personnelles ont été mises à jour avec succès.",
				{
					variant: "success",
				}
			);
			return true;
		} catch (error) {
			handleError({
				error,
				setError,
				errorMessage:
					"Quelque chose s'est mal passé, veuillez réessayer s'il vous plaît.",
			});
			return false;
		}
	};

	const sendEmailCode = async ({
		email,
		setError,
	}: {
		email: string;
		setError?: React.Dispatch<
			React.SetStateAction<string>
		>;
	}) => {
		const updateCodeStatus = (
			status: boolean
		) => {
			// state update for checked email is synchronous
			// so we can't use it to check if the email is the same as the checked email
			// if (checkedEmail === email) {
			setHasSentEmailCodeToCheckedEmail(
				status
			);
			// }
		};
		try {
			const emailVerificationCodes =
				settings.email_verification_sent_codes?.filter(
					(code) => code.email === email
				) || [];
			const emailVerificationSentCode =
				emailVerificationCodes?.[
					emailVerificationCodes?.length -
						1
				];

			if (emailVerificationSentCode) {
				//  check if email was sent less than 1 minute ago
				if (
					Date.now() -
						emailVerificationSentCode.timestamp <
					60000
				) {
					handleDevError(
						new Error(
							"Email verification code was sent less than 1 minute ago"
						)
					);
					updateCodeStatus(true);
					return true;
				}
			}

			await verifyEmailAPI.sendEmailCode(
				email
			);

			updateSettings({
				...settings,
				email_verification_sent_codes: [
					...(settings.email_verification_sent_codes ||
						[]),
					{
						email,
						timestamp: Date.now(),
					},
				],
			});
			updateCodeStatus(true);
			return true;
		} catch (error) {
			handleError({
				error,
				errorMessage:
					"Nous n'avons pas pu envoyer le code de vérification à votre adresse e-mail. Veuillez réessayer s'il vous plaît.",
				notifyError: false,
				setError,
			});
		}
		updateCodeStatus(false);
		return false;
	};

	const verifyEmailWithCode =
		async (params: {
			code: string;
			newEmail: string;
			setError: React.Dispatch<
				React.SetStateAction<string>
			>;
		}) => {
			const {
				code,
				newEmail,
				setError,
			} = params;

			try {
				await verifyEmailAPI.verifyEmailWithCode(
					code.toString(),
					newEmail.toString()
				);
				return true;
			} catch (error) {
				handleError({
					error,
					errorMessage:
						"Le Code de vérification que vous avez saisis est invalide",
					notifyError: false,
					setError,
				});
				return false;
			}
		};

	const handleRegister = async ({
		first_name,
		last_name,
		verification_code,
		password,
		email,
		setError,
	}: {
		first_name: string;
		last_name: string;
		verification_code: string;
		email: string;
		password: string;
		setError: React.Dispatch<
			React.SetStateAction<string>
		>;
	}) => {
		cdpClient?.track(
			"Register With Code Form Submitted",
			{
				eventType: "click",
				category: "Auth",
				label: "Register With Code",
				first_name,
				last_name,
				verification_code,
				password,
				email,
			}
		);

		try {
			await customerStoreAPI.createCustomer(
				{
					last_name,
					first_name,
					email,
					password,
					code: verification_code.toString(),
				}
			);

			refetch();
			resetToDefault();
			enqueueSnackbar(
				"Votre inscription a réussi.",
				{
					variant: "success",
				}
			);

			const channel =
				new BroadcastChannel(
					"user_session"
				);
			channel.postMessage({
				type: "registered",
				reference:
					randomReferenceForTab.current,
			});
			channel.close();
			return true;
		} catch (error) {
			handleError({
				error,
				setError,
				errorMessage:
					"Nous n'avons pas pu vous inscrire, veuillez réessayer s'il vous plaît.",
				notifyError: false,
			});
			return false;
		}
	};

	const handleLogIn = async ({
		email,
		password,
		setError,
		doReloadPage = false,
	}: {
		email: string;
		password: string;
		setError?: React.Dispatch<
			React.SetStateAction<string>
		>;
		doReloadPage?: boolean;
	}) => {
		try {
			await authStoreApi.logCustomerIn({
				email,
				password,
			});

			refetch();
			resetToDefault();
			window?.$chatwoot?.reset();
			enqueueSnackbar(
				"Votre connexion a réussi.",
				{
					variant: "success",
				}
			);

			const channel =
				new BroadcastChannel(
					"user_session"
				);
			channel.postMessage({
				type: "logged_in",
				reference:
					randomReferenceForTab.current,
			});
			channel.close();

			// Reload page if required
			if (doReloadPage) {
				window.location.reload();
			}

			return true;
		} catch (error) {
			handleError({
				error,
				setError,
				errorMessage:
					"Vérifiez votre mot de passe et réessayez, s'il vous plait.",
				notifyError: false,
			});

			return false;
		}
	};

	const handleLogout = () => {
		useDeleteSession.mutate(undefined, {
			onSuccess: () => {
				try {
					remove();
					enqueueSnackbar(
						"Vous avez été déconnecté avec succès. Au revoir!",
						{
							variant: "info",
						}
					);
					cdpClient?.track(
						"Logged Out",
						{
							category: "Account",
							label: "Logout",
							eventType: "click",
						}
					);

					const channel =
						new BroadcastChannel(
							"user_session"
						);
					channel.postMessage({
						type: "logged_out",
						reference:
							randomReferenceForTab.current,
					});
					channel.close();
					resetToDefault();
					window?.$chatwoot?.reset();
					cdpClient?.reset();
				} catch (e) {
					handleError({
						error: e,
						errorMessage:
							"Quelque chose s'est mal passé, veuillez réessayer s'il vous plaît.",
						notifyError: true,
						logError: true,
					});
				}
			},
			onError: (error: Error) => {
				handleError({
					error,
					errorMessage:
						"Quelque chose s'est mal passé, veuillez réessayer s'il vous plaît.",
					notifyError: true,
					logError: true,
				});
			},
		});
	};

	return (
		<AccountContext.Provider
			value={{
				customer,
				loadingCustomer,
				isCustomerRefetching,
				retrievingCustomer,
				checkIsRestricted,
				setAfterAuthDestination,
				checkIsAllreadyLoggedIn,
				refetchCustomer: refetch,
				sendEmailCode,
				verifyEmailWithCode,
				handleRegister,
				handleLogIn,
				updateCustomerEmail,
				updateCustomerPassword,
				updateCustomerDetails,
				handleLogout,
				checkedEmail,
				setCheckedEmail,
				emailExists,
				setEmailExists,
				checkIfEmailExists,
				hasSentEmailCodeToCheckedEmail,
				setHasSentEmailCodeToCheckedEmail,
				isRequestResetPassword,
				setIsRequestResetPassword,
				error,
				resetError,
			}}>
			<AuthDialog />
			{children}
		</AccountContext.Provider>
	);
};

export const useAccount = () => {
	const context = useContext(
		AccountContext
	);

	if (context === null) {
		throw new Error(
			"useAccount must be used within a AccountProvider"
		);
	}
	return context;
};
