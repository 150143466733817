import { StorePostAuthReq } from "models/Store.model";
import { medusaClient } from "utils/medusa-config";

const logCustomerIn = async (
	customer: StorePostAuthReq
) => {
	return await medusaClient.auth.authenticate(
		customer
	);
};

const getSession = async () => {
	await medusaClient.auth.getSession();
};

const checkifEmailExists = async (
	email: string
) => {
	const response =
		await medusaClient.auth
			.exists(email)
			.then((res) => {
				return res;
			})
			.catch((err) => {
				throw err;
			});
	return response;
};

const logCustomerOut =
	async (): Promise<void> => {
		await medusaClient.auth.deleteSession();
	};

const authStroeAPI = {
	logCustomerIn,
	getSession,
	checkifEmailExists,
	logCustomerOut,
};

export default authStroeAPI;
