import { FC } from "react";
import {
	Link as MuiLink,
	LinkProps as MuiLinkProps,
} from "@mui/material";
import Link from "next/link";

const LinkText: FC<MuiLinkProps> = ({
	children,
	href,
	target,
	rel,
	...rest
}) => (
	<MuiLink
		{...rest}
		color={"inherit"}
		underline='always'
		component={href ? Link : "span"}
		href={href}
		target={target}
		rel={rel}
		sx={{
			cursor: "pointer",
		}}>
		{children}
	</MuiLink>
);

export default LinkText;
