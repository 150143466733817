import { BACKEND_URL } from "utils/config";

const sendEmailCode = async (
	email: string
) => {
	const endpoint = `${BACKEND_URL}/store/custom/send-email/code`;
	try {
		const response = await fetch(
			endpoint,
			{
				method: "POST",
				headers: {
					"Content-Type":
						"application/json",
				},
				body: JSON.stringify({ email }),
			}
		);

		return response;
	} catch (error) {
		throw new Error(
			`Failed to send email code`
		);
	}
};

const verifyEmailWithCode = async (
	code: string,
	email: string
) => {
	const endpoint = `${BACKEND_URL}/store/custom/verify-code`;
	try {
		const response = await fetch(
			endpoint,
			{
				method: "POST",
				headers: {
					"Content-Type":
						"application/json",
				},
				body: JSON.stringify({
					code,
					email,
				}),
			}
		);

		return response;
	} catch (error) {
		throw new Error(
			`Failed to verify email with code`
		);
	}
};

const verifyEmailAPI = {
	sendEmailCode,
	verifyEmailWithCode,
};

export default verifyEmailAPI;
