import { StoreGetCustomersCustomerOrdersParams } from "@medusajs/medusa";
import {
	StorePostCustomersCustomerAddressesAddressReq,
	StorePostCustomersCustomerAddressesReq,
	StorePostCustomersCustomerPasswordTokenReq,
	StorePostCustomersCustomerReq,
	StorePostCustomersResetPasswordReq,
	StorePostCustomersReq,
	AddressPayload,
} from "models/Store.model";
import { medusaClient } from "utils/medusa-config";

interface ExtendedStorePostCustomersCustomerReq
	extends Omit<
		StorePostCustomersCustomerReq,
		"billing_address"
	> {
	billing_address?:
		| AddressPayload
		| string
		| null;
	code?: string;
}

const createCustomer = async (
	customer: StorePostCustomersReq & {
		code?: string;
	}
) => {
	try {
		return await medusaClient.customers.create(
			customer as StorePostCustomersReq
		);
	} catch (error) {
		throw error;
	}
};

const updateCustomer = async (
	payload: ExtendedStorePostCustomersCustomerReq
) => {
	try {
		const response =
			await medusaClient.customers.update(
				payload as StorePostCustomersCustomerReq
			);
		return response;
	} catch (error) {
		throw error;
	}
};

const retrieveCustomer = async () => {
	try {
		await medusaClient.customers.retrieve();
	} catch (error) {
		throw error;
	}
};

const resetPassword = async (
	payload: StorePostCustomersResetPasswordReq
) => {
	try {
		const response =
			await medusaClient.customers.resetPassword(
				payload
			);
		return response;
	} catch (error) {
		throw error;
	}
};

const generateCustomerPasswordToken =
	async (
		payload: StorePostCustomersCustomerPasswordTokenReq
	) => {
		try {
			await medusaClient.customers.generatePasswordToken(
				payload
			);
		} catch (error) {
			throw error;
		}
	};

const addAddress = async (
	payload: StorePostCustomersCustomerAddressesReq
) => {
	try {
		const response =
			await medusaClient.customers.addresses.addAddress(
				payload
			);
		return response;
	} catch (error) {
		throw error;
	}
};

const deleteAddress = async (
	addressId: string
) => {
	try {
		const response =
			await medusaClient.customers.addresses.deleteAddress(
				addressId
			);
		return response;
	} catch (error) {
		throw error;
	}
};

const updateAddress = async (
	addressId: string,
	payload: StorePostCustomersCustomerAddressesAddressReq
) => {
	try {
		const response =
			await medusaClient.customers.addresses.updateAddress(
				addressId,
				payload
			);
		return response;
	} catch (error) {
		throw error;
	}
};

const getCustomerOrders = async (
	params?: StoreGetCustomersCustomerOrdersParams,
	customHeaders?: Record<string, any>
) => {
	try {
		const { orders } =
			await medusaClient.customers.listOrders(
				params,
				customHeaders
			);
		return orders;
	} catch (error) {
		throw error;
	}
};

const customerStoreAPI = {
	createCustomer,
	updateCustomer,
	retrieveCustomer,
	generateCustomerPasswordToken,
	resetPassword,
	addAddress,
	updateAddress,
	deleteAddress,
	getCustomerOrders,
};

export default customerStoreAPI;
