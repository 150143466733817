import { FC, useState } from "react";

import BazaarTextField, {
	BazaarTextFieldProps,
} from "components/BazaarTextField";

import EyeToggleButton from "components/EyeToggleButton";

export type BazaarPasswordFieldProps =
	BazaarTextFieldProps & {};

const BazaarPasswordField: FC<
	BazaarPasswordFieldProps
> = ({ ...rest }) => {
	const [
		passwordVisibility,
		setPasswordVisibility,
	] = useState(false);
	const togglePasswordVisibility =
		() => {
			setPasswordVisibility(
				(prev) => !prev
			);
		};
	return (
		<BazaarTextField
			{...rest}
			mb={2}
			fullWidth
			autoComplete='on'
			variant='outlined'
			placeholder='*********'
			type={
				passwordVisibility
					? "text"
					: "password"
			}
			InputProps={{
				endAdornment: (
					<EyeToggleButton
						show={!passwordVisibility}
						click={
							togglePasswordVisibility
						}
					/>
				),
			}}
		/>
	);
};

export default BazaarPasswordField;
