import { Alert } from "@mui/material";
import BazaarCTA from "components/BazaarCta";
import BazaarPasswordField from "components/BazaarPasswordField";
import BazaarVerificationCodeField from "components/BazaarVerificationCodeField";
import { useAccount } from "contexts/AccountContext";
import { useAuthDialogContext } from "contexts/AuthDialogContext";
import { useFormik } from "formik";
import { FC, useState } from "react";
import { validateForm } from "utils/formValidations";
import * as yup from "yup";
interface RegisterWithCodeProps {
	destination?: string;
}

const RegisterWithCode: FC<
	RegisterWithCodeProps
> = ({ destination }) => {
	const {
		handleRegister,
		checkedEmail,
	} = useAccount();

	const { close: closeAuthDialog } =
		useAuthDialogContext();

	const [isLoading, setIsLoading] =
		useState(false);

	const [error, setError] = useState<
		null | string
	>(null);

	const handleFormSubmit =
		async (values: {
			// first_name: string;
			// last_name: string;
			verification_code: string;
			password: string;
		}) => {
			setIsLoading(true);
			setError(null);
			const {
				// first_name,
				// last_name,
				verification_code,
				password,
			} = values;

			try {
				const wasSuccessful =
					await handleRegister({
						first_name: "",
						last_name: "",
						verification_code,
						email: checkedEmail ?? "",
						password,
						setError,
					});
				if (!wasSuccessful) return;
				if (destination) {
					window.location.replace(
						destination
					);
				} else {
					//  because profile completion is disabled
					closeAuthDialog();
				}
			} catch (error) {
			} finally {
				setIsLoading(false);
			}
		};

	const {
		values,
		errors,
		touched,
		handleBlur,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues,
		onSubmit: handleFormSubmit,
		validationSchema: formSchema,
	});

	const handleGeneralChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		handleChange(e);
		setError(null);
	};

	const handleCodeChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		handleChange(e);
		setError(null);
	};

	return (
		<>
			<form onSubmit={handleSubmit}>
				<BazaarVerificationCodeField
					email={checkedEmail ?? ""}
					onChange={handleCodeChange}
					value={
						values.verification_code
					}
					onBlur={handleBlur}
					errorText={
						(errors.verification_code as string) ||
						""
					}
					touched={
						touched.verification_code
					}
					setError={setError}
				/>

				<BazaarPasswordField
					required
					name='password'
					label='Mot de passe'
					onBlur={handleBlur}
					onChange={handleGeneralChange}
					value={values.password}
					error={!!errors.password}
					helperText={
						!!touched.password &&
						(errors.password as string)
					}
				/>

				{error && (
					<Alert
						severity='error'
						sx={{ mb: 2 }}>
						{error}
					</Alert>
				)}
				<BazaarCTA
					fullWidth
					loading={isLoading}
					type='submit'
					sx={{ my: 2 }}>
					{"S'inscrire"}
				</BazaarCTA>
			</form>
		</>
	);
};

const initialValues = {
	// name: "",
	// first_name: "",
	// last_name: "",
	verification_code: "",
	password: "",
};

const formSchema = yup.object().shape({
	// first_name: validateForm.first_name,
	// last_name: validateForm.last_name,
	verification_code:
		validateForm.verificationCode,
	password: validateForm.new_password,
});

export default RegisterWithCode;
