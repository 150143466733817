"use client";

// import { SECTION_ROOT } from "utils/config";
import useSettings from "./useSettings";
import { MainSection } from "models/MainSection.model";

const useFashionPreference = () => {
	const {
		settings: {
			fashion_preference:
				fashionPreference,
		},
	} = useSettings() as {
		settings: {
			fashion_preference: MainSection;
		};
	};
	//  disable preferredHomePageSlug and set it to "/"
	// const handle =
	// 	fashionPreference?.handle;

	// const preferredHomePageSlug = handle
	// 	? `${SECTION_ROOT}/${handle}`
	// 	: "/";

	const preferredHomePageSlug = "/";

	return {
		fashionPreference,
		preferredHomePageSlug,
	};
};

export default useFashionPreference;
