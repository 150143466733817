import CustomDialog from "components/dialog-and-drawer/CustomDialog";
import { useAuthDialogContext } from "contexts/AuthDialogContext";
import Auth from "pages-sections/sessions/AuthTemplate";
import { FC } from "react";
import { cdpClient } from "utils/analytics";

interface AuthDialogProps {}

const AuthDialog: FC<
	AuthDialogProps
> = ({}) => {
	const {
		state: openDialog,
		close,
		open,
		title,
		hasHeader,
		isCenter,
		isFullScreen: fullscreen,
	} = useAuthDialogContext();

	const toggleDialog = (
		state: boolean
	) => {
		if (!state) {
			cdpClient?.track(
				"Auth dialog Close Button Clicked",
				{
					category:
						"Closed Auth Dialog",
					label: "Auth Dialog",
					eventType: "click",
				}
			);
			close();
		} else {
			cdpClient?.track(
				"Auth dialog Open Button Clicked",
				{
					category:
						"Opened Auth Dialog",
					label: "Auth Dialog",
					eventType: "click",
				}
			);
			open();
		}
	};

	return (
		<CustomDialog
			toggleDialog={toggleDialog}
			dialogOpen={openDialog}
			title={title}
			fullscreen={fullscreen}
			hasHeader={hasHeader}
			isCenter={isCenter}>
			<Auth />
		</CustomDialog>
	);
};

export default AuthDialog;
