import {
	Alert,
	AlertTitle,
	Link,
} from "@mui/material";
import { FlexBox } from "components/flex-box";
import {
	H3,
	Paragraph,
} from "components/Typography";

import MuiLink from "@mui/material/Link";
import BazaarCTA from "components/BazaarCta";
import { FC } from "react";
import { LEGAL_PRIVACY_POLICY_PAGE_URL } from "utils/config";

export interface FeatureAlertProps {
	title: string;
	description: string;
	reloadButtonText: string;
}

const PrerequisitiesAlert: FC<
	FeatureAlertProps
> = ({
	title,
	description,
	reloadButtonText,
}) => {
	const handleButtonClick = () => {
		window.location.reload();
	};

	return (
		<FlexBox
			flexDirection={"column"}
			my={6}
			mx='auto'
			maxWidth={600}>
			<Alert severity='error'>
				<AlertTitle>
					<H3 component='div'>
						{title}
					</H3>
				</AlertTitle>
				<Paragraph
					component='span'
					//  make text bold
					fontWeight={600}>
					{description}.&nbsp; Pour en
					savoir plus sur comment et à
					quelles fins nous utilisons
					les cookies, consultez
					notre&nbsp;
					<MuiLink
						color='text.primary'
						component={Link}
						href={
							LEGAL_PRIVACY_POLICY_PAGE_URL
						}
						target={"_blank"}
						rel={"noopener noreferrer"}
						fontWeight={600}>
						Politique de
						confidentialité.
					</MuiLink>
				</Paragraph>
			</Alert>
			<BazaarCTA
				fullWidth={false}
				sx={{ mt: 2 }}
				onClick={handleButtonClick}>
				{reloadButtonText}
			</BazaarCTA>
		</FlexBox>
	);
};

export default PrerequisitiesAlert;
