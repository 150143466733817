import { FC } from "react";

import BazaarTextField, {
	BazaarTextFieldProps,
} from "components/BazaarTextField";

export type BazaarEmailFieldProps =
	BazaarTextFieldProps & {};

const BazaarEmailField: FC<
	BazaarEmailFieldProps
> = ({ ...rest }) => {
	return (
		<BazaarTextField
			{...rest}
			mb={2}
			fullWidth
			size='medium'
			variant='outlined'
			placeholder='exemple@exemple.com'
		/>
	);
};

export default BazaarEmailField;
