import {
	closeSnackbar,
	enqueueSnackbar,
} from "notistack";
import { handleDevError } from "./handle-dev-error";
import Button from "@mui/material/Button";

const handleError = ({
	error,
	setError,
	logError = true,
	notifyError = true,
	errorMessage = "Nous n'avons pas pu traiter votre demande. Veuillez réessayer s'il vous plait.",
	principalAction,
	principalActionLabel,
	secondaryAction,
	secondaryActionLabel = "Fermer",
	severity = "error",
}: {
	error: Error;
	setError?: (error: string) => void;
	logError?: boolean;
	notifyError?: boolean;
	errorMessage?: string;
	principalAction?: () => void;
	principalActionLabel?: string;
	secondaryAction?: () => void;
	secondaryActionLabel?: string;
	severity?:
		| "error"
		| "warning"
		| "info"
		| "success";
}) => {
	if (setError)
		setError(
			errorMessage ?? error.message
		);
	// log error if environment is dev
	if (logError) handleDevError(error);
	// notify user with snackbar
	if (notifyError) {
		let action = (snackbarId) => {
			return (
				<>
					{principalAction && (
						<Button
							onClick={() => {
								principalAction &&
									principalAction();
								closeSnackbar(
									snackbarId
								);
								setError &&
									setError("");
							}}
							color='inherit'>
							{principalActionLabel}
						</Button>
					)}

					<Button
						onClick={() => {
							secondaryAction &&
								secondaryAction();
							closeSnackbar(snackbarId);
							setError && setError("");
						}}
						color='inherit'>
						{secondaryActionLabel}
					</Button>
				</>
			);
		};

		enqueueSnackbar(errorMessage, {
			variant: severity,
			action,
			autoHideDuration: null,
			preventDuplicate: true,
			hideIconVariant: true,
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
		});
	}
};

export default handleError;
