import AuthForm from "pages-sections/sessions/AuthForm";
import AuthWrapper from "components/AuthWrapper";
import { FC } from "react";
import { Container } from "@mui/material";
const AuthTemplate: FC = () => {
	return (
		<Container
			maxWidth='sm'
			sx={{
				minHeight: "100vh",
				my: 4,
				mx: "auto",
			}}>
			<AuthWrapper elevation={0}>
				<AuthForm />
			</AuthWrapper>
		</Container>
	);
};

export default AuthTemplate;
