export const handleDevError = (
	error: Error
) => {
	if (
		process.env.NODE_ENV ===
			"development" ||
		process.env.STORYBOOK_ENV ===
			"storybook"
	) {
		console.error(
			"This Error will not appear in production environnement",
			error
		);
	}

	// TODO: user facing error message
};
