import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Dialog, {
	DialogProps,
} from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import { TransitionProps } from "@mui/material/transitions";
import { H3 } from "components/Typography";
import { FlexRowCenter } from "components/flex-box";
import AuthHeader from "components/header/AuthHeader";
import useDeviceSize from "hooks/useDeviceSize";
import React, {
	FC,
	useCallback,
	useEffect,
} from "react";
const Transition = React.forwardRef(
	function Transition(
		props: TransitionProps & {
			children: React.ReactElement;
		},
		ref: React.Ref<unknown>
	) {
		return (
			<Slide
				direction='up'
				ref={ref}
				{...props}
			/>
		);
	}
);

interface Props {
	dialogOpen: boolean;
	toggleDialog: (
		state: boolean
	) => void;
	title?: string;
	fullscreen?: boolean;
	descktopWidth?: string;
	children: React.ReactNode;
	hasHeader?: boolean;
	isCenter?: boolean;
	dialogProps?: DialogProps;
}

const CustomDialog: FC<Props> = ({
	dialogOpen,
	toggleDialog,
	title,
	children,
	fullscreen,
	descktopWidth,
	hasHeader = false,
	isCenter = false,
	...props
}) => {
	const {
		upMd: isDescktop,
		// downSm: isMobile,
		downMd: isTablet,
	} = useDeviceSize();

	const handleClose =
		useCallback(() => {
			toggleDialog(false);
			// remove #dialog from path
			window &&
				window.location.hash === "";
		}, [toggleDialog]);

	useEffect(() => {
		// if path has #dialog, and dialog is not open, delete #dialog from path
		if (
			window.location.hash ===
				"#dialog" &&
			!dialogOpen
		) {
			window.history.replaceState(
				{
					direction: "backword",
					dialogOpen: false,
				},
				"",
				window.location.pathname +
					window.location.search
			);
		}
	}, [dialogOpen]);

	useEffect(() => {
		if (!dialogOpen) return;
		window.history.pushState(
			{
				direction: "forward",
				dialogOpen: true,
			},
			"",
			"#dialog"
		);

		window.addEventListener(
			"popstate",
			handleClose
		);

		return () => {
			window.removeEventListener(
				"popstate",
				handleClose
			);
		};
	}, [handleClose, dialogOpen]);

	return (
		<Dialog
			{...props}
			scroll='body'
			open={dialogOpen}
			fullScreen={
				fullscreen ?? isTablet
			}
			TransitionComponent={Transition}
			sx={{
				"& .MuiDialog-paper": isDescktop
					? {
							minWidth: (theme) =>
								theme.breakpoints
									.values[
									descktopWidth ?? "md"
								],
							paddingBottom: 8,
					  }
					: null,

				zIndex: (theme) =>
					theme.zIndex.drawer + 1000,
			}}
			keepMounted={false}
			disablePortal>
			<AppBar
				sx={{
					position: "relative",
					padding: 2,
				}}
				color='transparent'>
				<Toolbar
					variant='regular'
					disableGutters>
					<IconButton
						onClick={handleClose}
						aria-label='close'
						size='large'>
						<CloseIcon fontSize='large' />
					</IconButton>
					<H3>{title}</H3>
				</Toolbar>
			</AppBar>
			<Divider />
			{hasHeader && <AuthHeader />}
			{isCenter ? (
				<FlexRowCenter
					flexDirection='column'
					bgcolor={"white"}
					pb={
						isDescktop ? 8 : undefined
					}>
					<FlexRowCenter
						flexDirection='column'
						bgcolor={"white"}
						pb={
							isDescktop ? 8 : undefined
						}>
						{children}
					</FlexRowCenter>
				</FlexRowCenter>
			) : (
				children
			)}
		</Dialog>
	);
};

export default CustomDialog;
