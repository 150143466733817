"use client";

import { styled } from "@mui/material";
import Container from "@mui/material/Container";

export const AuthStyledContainer =
	styled(Container)({
		gap: 2,
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	});
