import Medusa from "@medusajs/medusa-js";
import { QueryClient } from "@tanstack/react-query";
import { BACKEND_URL } from "./config";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			staleTime: 1000 * 60 * 60 * 24,
			retry: 1,
		},
	},
});

const medusaClient = new Medusa({
	baseUrl: BACKEND_URL,
	maxRetries: 3,
});

let DEFAULT_CURRENCY_CODE = "EUR";

if (
	process.env
		.NEXT_PUBLIC_DEFAULT_CURRENCY_CODE
) {
	DEFAULT_CURRENCY_CODE =
		process.env
			.NEXT_PUBLIC_DEFAULT_CURRENCY_CODE;
}

export {
	// Utils
	queryClient,
	medusaClient,
	DEFAULT_CURRENCY_CODE,
};
