import {
	Box,
	BoxProps,
	TextField,
	TextFieldProps,
} from "@mui/material";
import CustomTooltip from "components/CustomToolTip";
import { FC } from "react";
import { Small } from "./Typography";
import { FlexBox } from "./flex-box";

export type BazaarTextFieldProps =
	TextFieldProps &
		BoxProps & {
			tooltip?: string;
			tooltipVariant?: "help" | "info";
		};

const BazaarTextField: FC<
	BazaarTextFieldProps
> = ({
	label,
	// InputProps,
	tooltip,
	tooltipVariant,
	...props
}) => {
	const boxProps: BoxProps = {};
	const textFieldProps: TextFieldProps =
		{};

	for (const key in props) {
		if (spacePropList.includes(key)) {
			boxProps[key] = props[key];
		} else
			textFieldProps[key] = props[key];
	}

	return (
		<Box {...boxProps}>
			{label && (
				<Small
					display='block'
					mb={1}
					textAlign='left'
					fontWeight='600'
					color='grey.700'>
					<FlexBox
						alignContent='center'
						alignItems='center'>
						{/* // add a * to the label if the field is required */}
						{textFieldProps?.required
							? `${label} *`
							: label}
						{tooltip && (
							<CustomTooltip
								description={tooltip}
								variant={tooltipVariant}
							/>
						)}
					</FlexBox>
				</Small>
			)}

			<TextField
				{...textFieldProps}
				fullWidth
				size='medium'
				aria-label={label as string}
				role='textbox'
			/>
		</Box>
	);
};

export const spacePropList = [
	"m",
	"mt",
	"mr",
	"mb",
	"ml",
	"mx",
	"my",
	"p",
	"pt",
	"pr",
	"pb",
	"pl",
	"px",
	"py",
	"margin",
	"marginTop",
	"marginRight",
	"marginBottom",
	"marginLeft",
	"marginX",
	"marginY",
	"padding",
	"paddingTop",
	"paddingRight",
	"paddingBottom",
	"paddingLeft",
	"paddingX",
	"paddingY",
];

export default BazaarTextField;
